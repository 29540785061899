import React from "react";
import { useDispatch, useSelector } from "react-redux";
import JessicaLogo from "assets/images/interview/toby.png";
import { Player } from "@lottiefiles/react-lottie-player";
import WaveformAnimation from "../animations/Waveform Animation.json";
import "../styles/WaveForm.css";
import { INTERVIEW_STATE, INTERVIEW_STATUS, SPEED_VALUES } from "Utils";
import { changeSpeedStep } from "store/meetingSlice";
import Avatar from "components/Avatar";
import useInterview from "hooks/useInterview";

const Room = () => {
  const dispatch = useDispatch();
  const {
    interviewState,
    isPlayingAudio: isPlaying,
    speedStep,
  } = useSelector((state) => state.meeting);
  const { interviewStatus: status, image, assistantImage } = useInterview()
  const isInterviewerSpeaking =
    interviewState === INTERVIEW_STATE.INTERVIEWER_SPEAKING ||
    interviewState === INTERVIEW_STATE.SENDING;
  const isUserSpeaking =
    !isInterviewerSpeaking && status < INTERVIEW_STATUS.DONE;
  const isUserPlaying =
    interviewState === INTERVIEW_STATE.RECORDING ||
    (interviewState === INTERVIEW_STATE.REVIEWING && isPlaying);
  return (
    <div className="room">
      <div
        className={
          "person interviewer" + (!isInterviewerSpeaking ? "" : " active")
        }
      >
        <div
          className={assistantImage?.trim() ? "avatar" : "avatar no-round"}
          style={{
            backgroundImage: JessicaLogo,
          }}
        >
          <img className={assistantImage?.trim() ? "avatar" : "avatar no-round"} src={assistantImage?.trim() || JessicaLogo} alt="" />
        </div>
        <div className={`wave-form ${isInterviewerSpeaking ? "active" : ""}`}>
          <div className="speed hide">{SPEED_VALUES[speedStep]}x</div>
          <Player
            key={isPlaying}
            autoplay={isPlaying}
            loop={isPlaying}
            src={WaveformAnimation}
            className="wave"
          ></Player>
          <div className="speed" onClick={() => dispatch(changeSpeedStep())}>
            {SPEED_VALUES[speedStep]}x
          </div>
        </div>
        <div className="status">
          {isInterviewerSpeaking ? "Asking" : "\u00A0"}
        </div>
      </div>
      <div className={"person user" + (isInterviewerSpeaking ? "" : " active")}>
        <Avatar className="avatar" src={image} alt="user" />
        <div className={`wave-form ${isUserSpeaking ? "active" : ""}`}>
          {/* <div className="speed hide">0.75x</div> */}
          <Player
            key={isUserPlaying}
            autoplay={isUserPlaying}
            loop={isUserPlaying}
            src={WaveformAnimation}
            className="wave"
          ></Player>
          {/* <div className="speed">0.75x</div> */}
        </div>
        <div className="status">{isUserSpeaking ? "Answering" : "\u00A0"}</div>
      </div>
    </div>
  );
};

export default Room;

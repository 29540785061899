import { createSlice } from "@reduxjs/toolkit";
import { INTERVIEW_STATE, SPEED_VALUES } from "Utils";

const initialState = {
  interviewState: INTERVIEW_STATE.BEFORE_START,
  messages: [],
  countTime: 0,
  volume: 0,
  audio: null,
  isPlayingAudio: false,
  progress: 0,
  duration: Infinity,
  transcript: [],
  speechId: 0,
  speedStep: 0,
  forceFinished: false,
  /*
  0 : interviewer speaking
  1 : user speaking
  2 : user paused speaking
  3 : user finished speaking
  4 : user sending recording
  */
  time: 0,
  openPopup: false,
};

const meetingSlice = createSlice({
  name: "meeting",
  initialState,
  reducers: {
    setInterviewState: (state, action) => {
      state.interviewState = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = [...action.payload];
    },
    setCountTime: (state, action) => {
      state.countTime = action.payload;
    },
    setAudio: (state, action) => {
      state.audio = action.payload;
    },
    setPlayingAudio: (state, action) => {
      state.isPlayingAudio = action.payload;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
    setVolume: (state, action) => {
      state.volume = action.payload;
    },
    setDuration: (state, action) => {
      state.duration = action.payload;
    },
    addTranscript: (state, action) => {
      const { resultIndex } = action.payload;

      if (state.transcript.length === 0) {
        return {
          ...state,
          transcript: [{ ...action.payload, index: state.speechId }],
        };
      }

      let newTranscript = [...state.transcript];
      const lastContent = newTranscript.pop();
      if (
        lastContent.index !== state.speechId ||
        lastContent.resultIndex !== resultIndex
      ) {
        newTranscript.push(lastContent);
      }
      newTranscript.push({ ...action.payload, index: state.speechId });

      return {
        ...state,
        transcript: newTranscript,
      };
    },
    increaseSpeechId: (state) => {
      state.speechId += 1;
    },
    clearTranscript: (state, action) => {
      state.transcript = [];
      state.speechId = 0;
    },
    changeSpeedStep: (state) => {
      state.speedStep =
        state.speedStep >= SPEED_VALUES.length - 1 ? 0 : state.speedStep + 1;
    },
    initMeeting: () => ({ ...initialState }),
    setForceFinished: (state, action) => {
      state.forceFinished = action.payload;
    },
    setTime: (state, action) => {
      state.time = action.payload;
    },
    setOpenPopup: (state, action) => {
      state.openPopup = !!action.payload;
    },
  },
});

export const {
  setInterviewState,
  setMessages,
  setCountTime,
  setAudio,
  setPlayingAudio,
  setProgress,
  setVolume,
  turnonRecording,
  setDuration,
  addTranscript,
  clearTranscript,
  increaseSpeechId,
  changeSpeedStep,
  initMeeting,
  setForceFinished,
  setTime,
  setOpenPopup,
} = meetingSlice.actions;

export default meetingSlice.reducer;

import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import JessicaLogo from "assets/images/interview/toby.png";
import AnimationBack from "../animations/Square Conf.json";
import "../styles/Congrats.css";
import { capitalize } from "../../../Utils";
import Avatar from "components/Avatar";
import useInterview from "hooks/useInterview";

const Congrats = () => {
  const { image, name, assistantImage } = useInterview();
  // const closeTab = () => {
  //   window.opener = null; // Helps in some situations  
  //   window.open('', '_self'); // Attempts to close  
  //   window.close(); // Attempt to close  
  // }
  return (
    <div className="congrats-container">
      <div className="congrats back">
        <Player autoplay loop src={AnimationBack} className="conf" />
        <Player autoplay loop src={AnimationBack} className="conf" />
        <Player autoplay loop src={AnimationBack} className="conf" />
        <Player autoplay loop src={AnimationBack} className="conf" />
        <Player autoplay loop src={AnimationBack} className="conf" />
      </div>
      <div className="congrats front flex items-center justify-center p-4">
        <div className="max-w-full w-[800px] max-h-[100%] h-750px rounded-[40px] bg-cta-blue flex flex-col items-center justify-center gap-6 sm:gap-10 p-4 sm:p-10">
          <div className="flex">
            <img
              src={assistantImage?.trim() || JessicaLogo}
              alt="jessica"
              className="rounded-full w-[126px] h-[133px] sm:w-[155px] sm:h-[163px] md:w-[180px] md:h-[193px] -mr-7"
            />
            <Avatar
              className="avatar w-[133px] h-[133px] sm:w-[163px] sm:h-[163px] md:w-[193px] md:h-[193px]"
              src={image}
              alt="user"
            />
          </div>
          <div className="font-figtree text-[26px] sm:text-[32px] lg:text-[40px] font-[700] leading-[50%] tracking-[-0.4px] text-center">{`Great job, ${capitalize(
            name
          )} 🎉`}</div>
          <div className="font-figtree text-[16px] sm:text-[18px] lg:text-[24px] font-[400] leading-[137.5%] tracking-[-0.24px] text-center px-0">
            <span className="block">Thanks for your interview!</span>
            <span className="block">You provided all the insights needed to
            create some hit content!</span>
            <span className="block">will get back to you with the results in
            your mailbox.</span>
          </div>
          <div className="flex flex-wrap w-full justify-center gap-2.5 font-inter text-[16px] leading-[150%]">
            {/* <button
              className="link interact flex-1 max-w-[235px] text-nowrap border border-white rounded-full px-10 py-2 text-cta-blue bg-white font-inter text-[16px] font-[600] leading-[150%]"
              style={{ color: "#0e24d6" }}
              onClick={closeTab}
            >
              Close the tab
            </button> */}
            You can close the tab
          </div>
        </div>
      </div>
    </div>
  );
};

export default Congrats;

import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import React, { useState } from "react";
import "../styles/InterviewTips.css";
import TipsIcon from "assets/images/tips-icon.svg";
import smileIcon from "assets/images/tips-icons/smile.svg";
import linkedinIcon from "assets/images/tips-icons/linkedin.svg";
import downloadIcon from "assets/images/tips-icons/download.svg";
import clockIcon from "assets/images/tips-icons/clock.svg";
import sandboxIcon from "assets/images/tips-icons/codesandbox.svg";
import microphoneIcon from "assets/images/meeting/microphone-01.svg";

const InterviewTips = () => {
  const [openTips, setOpenTips] = useState(false);
  const changeTipsState = () => {
    setOpenTips((prev) => !prev);
  };

  return (
    <div className="interview-tips-container">
      <div className={"interview-tips" + (openTips ? " active" : "")}>
        <div className="interview-tips-action" onClick={changeTipsState}>
          <img src={TipsIcon} alt="tips-icon"></img>
          <span>
            Some <span className="interview-tips-blue">tips</span> for our
            interview!
          </span>
          {openTips ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </div>
        <ul className="interview-tips-body">
          {openTips && (
            <>
              <li>
                <img src={microphoneIcon} alt="microphone" /> Please allow your device to
                record audio. If using a desktop computer, ensure you have an
                external microphone connected.
              </li>
              <li>
                <img src={smileIcon} alt="smile" /> Relax and be yourself. It’s
                all about providing your authentic insights. There’s no wrong
                answer.
              </li>
              <li>
                <img src={clockIcon} alt="clock" /> I’ll ask 4-5 interview
                questions. Answer each for at least 20 seconds for an authentic
                post.
              </li>
              <li>
                <img src={sandboxIcon} alt="sandbox" /> I’ll adapt the interview
                to you. Just tell me a topic to focus on, to skip a question,
                etc.
              </li>
              <li>
                <img src={linkedinIcon} alt="linkedin" /> Answer 3 or more
                questions to get your post drafts.
              </li>
              <li>
                <img src={downloadIcon} alt="download" /> Leave your interview
                at any point & come back to it later. We’ll save your progress.
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default InterviewTips;

// import { ReactComponent as Close } from "assets/images/close.svg";
import React from "react";
import "../styles/FillBoard.css";
import PlayIcon from "assets/images/play.svg"
import { INTERVIEW_STATUS } from "Utils";
import useInterview from "hooks/useInterview";

const FillBoard = ({ closeInterview, startInterview }) => {
  const { interviewStatus } = useInterview();
  return (
    <div className="fill-board">
      <div className="close-button">
        {/* <Close onClick={closeInterview} /> */}
      </div>
      {interviewStatus < INTERVIEW_STATUS.INTERVIEWING && <div className="start-button" onClick={startInterview}>
        <img src={PlayIcon} alt="start"/>
      </div>}
    </div>
  );
};

export default FillBoard;

import { showInfoMessage } from "Utils";
import React, { memo, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addTranscript,
  clearTranscript,
  increaseSpeechId,
} from "store/meetingSlice";

const SpeechRecognizer = () => {
  const dispatch = useDispatch();
  const { interviewState } = useSelector((state) => state.meeting);
  const isListeningRef = useRef(false);
  const speechRecognitionRef = useRef(null);

  useEffect(() => {
    // Check if browser supports the Web Speech API
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      showInfoMessage(
        "Speech Recognition is not available in this browser. You can proceed with the interview without any issues, but live transcription will not be available."
      );
      return;
    }
    if (!speechRecognitionRef.current) {
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();

      recognition.lang = "en-US";
      recognition.continuous = true; // Continue capturing until stopped
      recognition.interimResults = true;

      recognition.onresult = (event) => {
        let finalTranscript = ""; // Variable to hold the final transcript

        for (let i = event.resultIndex; i < event.results.length; ++i) {
          finalTranscript += event.results[i][0].transcript;
        }
        console.log(`speech : ${finalTranscript}`);
        // Dispatch the final transcript using your redux action
        dispatch(
          addTranscript({
            content: finalTranscript,
            resultIndex: event.resultIndex,
          })
        );
      };

      recognition.onerror = (error) => {
        if (error.error === "no-speech") return;
        recognition.start();
      };
      recognition.onstart = () => {
        dispatch(increaseSpeechId());
        isListeningRef.current = true;
      };
      recognition.onend = () => {
        isListeningRef.current = false;
      };
      recognition.onaudioend = () => {
        isListeningRef.current = false;
      };
      speechRecognitionRef.current = recognition;
    }
    return () => {
      if (speechRecognitionRef.current) {
        speechRecognitionRef.current.stop();
      }
    };
  }, [dispatch]);

  useEffect(() => {
    const controlListening = () => {
      if (!speechRecognitionRef.current) return;
      try {
        if (interviewState === 1 && !isListeningRef.current) {
          speechRecognitionRef.current.start();
        } else if (interviewState !== 1 && isListeningRef.current) {
          speechRecognitionRef.current.stop();
        }
      } catch (error) {
        console.log(error);
      }

      if (interviewState === 0) {
        dispatch(clearTranscript());
      }
    };
    controlListening();
  }, [dispatch, interviewState]);

  return <></>;
};

export default memo(SpeechRecognizer);

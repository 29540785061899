import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Meeting from "pages/Meeting";
import "simplebar-react/dist/simplebar.min.css";
import NotFound from "pages/Etc/NotFound";

function App() {
  return (
    <div className="App">
      <div className="body">
        <main className="with-animation">
          <Routes>
            <Route path="/:interviewId" element={<Meeting />} />
            <Route path="/not-found" element={<NotFound />} />
            <Route path="*" element={<Navigate replace to="/not-found" />} />
          </Routes>
        </main>
      </div>
    </div>
  );
}

export default App;

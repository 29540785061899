import React, { useEffect, useState } from "react";
import { ReactComponent as ProgressBlack } from "assets/images/progress-black.svg";
import { ReactComponent as ProgressWhite } from "assets/images/progress-white.svg";
import { useSelector } from "react-redux";
import { Player } from "@lottiefiles/react-lottie-player";
import Clap from "../animations/Clap.json"
import "../styles/Progress.css"
import { INTERVIEW_STATUS } from "Utils";

const InterviewProgress = () => {
  const [showFirstClap, setShowFirstClap] = useState(false)
  const [showSecondClap, setShowSecondClap] = useState(false)
  const { messages } = useSelector((state) => state.meeting);
  const { status } = useSelector((state) => state.interview);
  const questionsCount = messages.filter((message) => message.role === 1).length;
  let step = 1;
  if (status === INTERVIEW_STATUS.DONE) step = 4;
  else if (questionsCount > 5) step = 3;
  else if (questionsCount > 2) step = 2;
  const showClap1 = () => {
    setShowFirstClap(true)
    setTimeout(() => {
      setShowFirstClap(false)
    }, 10000)
  }
  const showClap2 = () => {
    setShowSecondClap(true)
    setTimeout(() => {
      setShowSecondClap(false)
    }, 10000)
  }
  useEffect(() => {
    if(messages.length === 5) showClap1()
    if(messages.length === 11) showClap2()
  }, [messages.length])
  return (
    <div className="progress">
      <div className={`progress-bar step${step}`}>
        {showFirstClap && <Player autoplay loop src={Clap} className="clap first"></Player>}
        {showSecondClap && <Player autoplay loop src={Clap} className="clap second"></Player>}
        <ProgressBlack className="bar" />
        <div className="mask">
          <ProgressWhite className="bar" />
        </div>
      </div>
    </div>
  );
};

export default InterviewProgress;

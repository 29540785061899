import { configureStore } from "@reduxjs/toolkit";
import meetingReducer from "./meetingSlice";
import interviewSlice from "./interviewSlice";

const store = configureStore({
  reducer: {
    meeting: meetingReducer,
    interview: interviewSlice,
  },
});

export default store;

import SweetAlert from "sweetalert2";
import moment from "moment-timezone";

export const INTERVIEW_STATUS = {
  CREATED: -1,
  INTERVIEWING: 0,
  DONE: 1,
  REJECTED: 2,
};

export const INTERVIEW_STATE = {
  INTERVIEWER_SPEAKING: 0,
  RECORDING: 1,
  PAUSED_RECORDING: 2,
  REVIEWING: 3,
  SENDING: 4,
  BEFORE_START: 5,
};

export const PAGE_TYPES = {
  MEETING: "meeting",
  REGISTRATION: "register",
  POSTS: "posts",
  SCHEDULE: "schedule",
  MANAGE: "manage",
  PERSONALIZATION: "personalization",
};

export const SPEED_VALUES = ["1.00", "1.25", "1.50", "0.50", "0.75"];

export const QUEUE_URL = "https://q-ueue.ai";
export const ADMIN_HOMEPAGE_URL = "https://jessica.q-ueue.ai";

export const get5MinsDate = () => {
  const date = new Date();
  const timestamp = date.getTime();
  const fiveMinsTimestamp = 5 * 60 * 1000;
  let newTimestamp =
    Math.ceil(timestamp / fiveMinsTimestamp) * fiveMinsTimestamp;
  return new Date(newTimestamp);
};

export const get15MinsDate = () => {
  const date = new Date();
  const timestamp = date.getTime();
  const fiveMinsTimestamp = 15 * 60 * 1000;
  let newTimestamp =
    Math.ceil(timestamp / fiveMinsTimestamp) * fiveMinsTimestamp;
  return new Date(newTimestamp);
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const showSuccessMessage = (content) => {
  return SweetAlert.fire({
    icon: "success",
    title: "Success",
    html: content,
    showConfirmButton: true,
    confirmButtonText: "OK",
  });
};

export const showErrorMessage = (content) => {
  return SweetAlert.fire({
    icon: "error",
    title: "Error",
    html: content,
    showConfirmButton: true,
    confirmButtonText: "OK",
  });
};

export const showInfoMessage = (content) => {
  SweetAlert.fire({
    icon: "info",
    title: "Info",
    html: content,
    showConfirmButton: true,
    confirmButtonText: "OK",
  });
};

export const showQuestionMessage = (content) => {
  return SweetAlert.fire({
    icon: "question",
    title: "Confirm",
    html: content,
    showConfirmButton: true,
    confirmButtonText: "OK",
  });
};

export function getDateTime(
  inputDate,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
) {
  const now = inputDate;
  const options = {
    timeZone: timeZone,
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const formattedDateTime = now.toLocaleString("en-US", options);

  // Extract individual components
  const [date, time] = formattedDateTime.split(", ");
  const [month, day, year] = date.split("/");
  const [hour, minute] = time.split(":");

  return {
    day: parseInt(day),
    month: parseInt(month) - 1,
    year: parseInt(year),
    hour:
      parseInt(hour) +
      (time.includes("PM") && parseInt(hour) < 12 ? 12 : 0) -
      (time.includes("AM") && parseInt(hour) === 12 ? 12 : 0),
    minute: parseInt(minute),
  };
}

export function getCurrentDateTime(
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
) {
  const now = new Date();
  const options = {
    timeZone: timeZone,
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const formattedDateTime = now.toLocaleString("en-US", options);

  // Extract individual components
  const [date, time] = formattedDateTime.split(", ");
  const [month, day, year] = date.split("/");
  const [hour, minute] = time.split(":");

  return {
    day: parseInt(day),
    month: parseInt(month) - 1,
    year: parseInt(year),
    hour:
      parseInt(hour) +
      (time.includes("PM") && parseInt(hour) < 12 ? 12 : 0) -
      (time.includes("AM") && parseInt(hour) === 12 ? 12 : 0),
    minute: parseInt(minute),
  };
}

export function capitalize(word) {
  if (!word || word.length < 1) return "";
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const splitPost = (post) => {
  if (!post) {
    return { title: "", content: "", tags: [] };
  }
  // Split the post by new lines
  const lines = post.split("\n");
  if (!lines || lines.length === 0) {
    return { title: "", content: "", tags: [] };
  }

  // The title is the first line
  let title = lines.shift();

  while (lines.length > 0 && !/[A-Za-z0-9]+/.test(title)) {
    title = lines.shift();
  }

  // The rest of the array is joined back into a string to form the content
  let content = lines.join("\n");

  // Find tags - words starting with '#'
  let tags = [];
  const words = post.match(/\S+/g) || [];

  words.forEach((word) => {
    if (word.startsWith("#") && word.replace(/#/g, "").length > 0) {
      tags.push(word);
    }
  });

  tags = tags.filter((tag, index, self) => self.indexOf(tag) === index);

  // The result includes title, content, and tags
  return { title, content, tags };
};

export function checkFutureTime({ min, hour, date, month, year, timezone }) {
  const inputDate = moment.tz([year, month, date, hour, min, 0, 0], timezone);
  const currentDate = moment.tz(timezone);
  return inputDate.isAfter(currentDate);
}

export const isValidEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const cutLinkedin = (linkedin) =>
  linkedin
    .replace(/^\/+|\/+$/g, "")
    .replace(/\s/g, "")
    .split("/")
    .pop();

export function obfuscateEmail(email) {
  if (!isValidEmail(email)) return "";
  const [local, domain] = email.split("@");
  const obfuscatedLocal =
    local.charAt(0) + "*".repeat(local.length - 2) + local.slice(-1);
  const domainParts = domain.split(".");
  const obfuscatedDomain =
    domainParts[0].charAt(0) +
    "*".repeat(domainParts[0].length - 2) +
    domainParts[0].slice(-1) +
    "." +
    domainParts[1];

  return `${obfuscatedLocal}@${obfuscatedDomain}`;
}

export function toUtcDate(dateObj) {
  const { year, month, day, hour, minute, timezone } = dateObj;
  const options = {
    timeZone: timezone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };

  // Format the date string according to the timezone
  const formattedDateParts = new Intl.DateTimeFormat(
    "en-US",
    options
  ).formatToParts(new Date(Date.UTC(year, month - 1, day, hour, minute)));

  const dateStr = `${
    formattedDateParts.find((part) => part.type === "year").value
  }-${formattedDateParts.find((part) => part.type === "month").value}-${
    formattedDateParts.find((part) => part.type === "day").value
  }T${formattedDateParts.find((part) => part.type === "hour").value}:${
    formattedDateParts.find((part) => part.type === "minute").value
  }:${formattedDateParts.find((part) => part.type === "second").value}`;

  // Return the timestamp in milliseconds since the epoch
  return new Date(dateStr + "Z").getTime();
}

export const getInterviewDate = (dateObj) => {
  const { year, month, day, hour, minute, timezone } = dateObj;

  // Create a date string
  const dateString = `${year}-${String(month + 1).padStart(2, "0")}-${String(
    day
  ).padStart(2, "0")}T${String(hour).padStart(2, "0")}:${String(
    minute
  ).padStart(2, "0")}:00`;

  // Parse with specified time zone
  const date = moment.tz(dateString, timezone);

  // Convert to local time zone
  const localDate = date.clone().tz(moment.tz.guess());

  // Get today's and yesterday's start of day for comparison
  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "days").startOf("day");

  // Determine which message to display
  let displayMessage;
  if (localDate.isSame(today, "d")) {
    displayMessage = "Today";
  } else if (localDate.isSame(yesterday, "d")) {
    displayMessage = "Yesterday";
  } else {
    displayMessage = localDate.format("MMM Do");
  }

  return displayMessage;
};

export function countWords(text) {
  // Use a regular expression to match words
  const words = text.match(/\b\w+\b/g);

  // If no words are found, return 0
  if (!words) {
    return 0;
  }

  // Return the number of words
  return words.length;
}

export const downloadFile = (url, fileName) => {
  if (!url.trim()) {
    console.log("Error before downloading : url is required");
    return;
  }
  if (!fileName?.trim()) {
    fileName = url
      .split("/")
      .filter((word) => word.trim())
      .pop();
    if (!fileName) {
      console.log("Error before downloading : fileName is required", fileName);
      return;
    }
  }
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => console.error("Error downloading image:", error));
};

import React from "react";
import Jessica from "assets/images/others/jessica-orange-red.png";;

const NotFound = () => {
  return (
    <div className="w-full flex-1 flex flex-col justify-center items-center bg-off-white-light p-4">
      <img src={Jessica} alt="jessica" className="w-[200px] h-auto" />
      <div className="font-figtree text-[150px] leading-[150%]">404</div>
      <div className="flex flex-col items-center justify-center gap-[22px]">
        <div className="font-figtree text-[24px] text-center leading-[150%]">
          Ooops, we can't find this page
        </div>
      </div>
    </div>
  );
};

export default NotFound;

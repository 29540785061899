import React from "react";

const AudioProgress = ({progress}) => { //progress : 0 ~ 100
  return (
    <div className="audio-play">
      <div className="group back">
        <div className="line line3"></div>
        <div className="line line4"></div>
        <div className="line line3"></div>
        <div className="line line4"></div>
        <div className="line line3"></div>
        <div className="line line1"></div>
        <div className="line line3"></div>
        <div className="line line2"></div>
        <div className="line line3"></div>
        <div className="line line1"></div>
        <div className="line line1"></div>
        <div className="line line3"></div>
        <div className="line line2"></div>
        <div className="line line3"></div>
        <div className="line line1"></div>
        <div className="line line3"></div>
        <div className="line line4"></div>
        <div className="line line3"></div>
        <div className="line line4"></div>
        <div className="line line3"></div>
      </div>
      <div className="mask" id="id-mask" style={{width: `${progress}%`}}>
        <div className="group fore">
          <div className="line line3"></div>
          <div className="line line4"></div>
          <div className="line line3"></div>
          <div className="line line4"></div>
          <div className="line line3"></div>

          <div className="line line1"></div>
          <div className="line line3"></div>
          <div className="line line2"></div>
          <div className="line line3"></div>
          <div className="line line1"></div>

          <div className="line line1"></div>
          <div className="line line3"></div>
          <div className="line line2"></div>
          <div className="line line3"></div>
          <div className="line line1"></div>

          <div className="line line3"></div>
          <div className="line line4"></div>
          <div className="line line3"></div>
          <div className="line line4"></div>
          <div className="line line3"></div>
        </div>
      </div>
    </div>
  );
};

export default AudioProgress;

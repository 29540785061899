import { SERVER_URL } from "api";
import axios from "axios";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAssistantImage,
  setAssistantName,
  setImage,
  setName,
  setInterviewId,
  setInterviewStatus,
} from "store/interviewSlice";
import { setMessages } from "store/meetingSlice";

const useInterview = () => {
  const { interviewId, status, image, name, assistantName, assistantImage } = useSelector(
    (state) => state.interview
  );
  const dispatch = useDispatch();

  const fetchInterviewData = useCallback(
    async (iId) => {
      if (!iId) return;
      try {
        let response = await axios.get(`${SERVER_URL}/interview_data/${iId}`);
        dispatch(setInterviewStatus(response.data.interview.status));
        dispatch(setImage(response.data.interview.image));
        dispatch(setAssistantName(response.data.interview.interviewer_name));
        dispatch(setAssistantImage(response.data.interview.interviewer_image));
        dispatch(setInterviewId(response.data.interview.id));
        dispatch(setName(response.data.interview.name));
        dispatch(setMessages(response.data.messages));
        return {
          interviewId: iId,
          interviewStatus: response.data.status,
          messages: response.data.messages,
        };
      } catch (error) {
        throw error;
      }
    },
    [dispatch]
  );
  return {
    interviewId,
    interviewStatus: status,
    fetchInterviewData,
    image,
    name,
    assistantName,
    assistantImage
  };
};

export default useInterview;

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jessica11 from "assets/audios/jessica-1-1.mp3";
import jessica12 from "assets/audios/jessica-1-2.mp3";
import jessica13 from "assets/audios/jessica-1-3.mp3";
import jessica21 from "assets/audios/jessica-2-1.mp3";
import jessica22 from "assets/audios/jessica-2-2.mp3";
import jessica23 from "assets/audios/jessica-2-3.mp3";
import jessica31 from "assets/audios/jessica-3-1.mp3";
import jessica32 from "assets/audios/jessica-3-2.mp3";
import jessica33 from "assets/audios/jessica-3-3.mp3";
import jessica41 from "assets/audios/jessica-4-1.mp3";
import jessica42 from "assets/audios/jessica-4-2.mp3";
import jessica43 from "assets/audios/jessica-4-3.mp3";
import jessica51 from "assets/audios/jessica-5-1.mp3";
import jessica52 from "assets/audios/jessica-5-2.mp3";
import jessica53 from "assets/audios/jessica-5-3.mp3";
import jessica61 from "assets/audios/jessica-6-1.mp3";
import jessica62 from "assets/audios/jessica-6-2.mp3";
import jessica63 from "assets/audios/jessica-6-3.mp3";
import backStart from "assets/audios/back-start.wav";
import backClap from "assets/audios/back-clap.wav";
import back1 from "assets/audios/back-0.wav";
import back2 from "assets/audios/back-1.wav";
import back3 from "assets/audios/back-2.wav";

import { INTERVIEW_STATE, INTERVIEW_STATUS } from "Utils";
import { setPlayingAudio } from "store/meetingSlice";
// import axios from "axios";
// import { SERVER_URL } from "api";
import useInterview from "hooks/useInterview";

const WaitingPlayer = () => {
  const jessicaAudios = useMemo(
    () => [
      [jessica11, jessica12, jessica13],
      [jessica21, jessica22, jessica23],
      [jessica31, jessica32, jessica33],
      [jessica41, jessica42, jessica43],
      [jessica51, jessica52, jessica53],
      [jessica61, jessica62, jessica63],
    ],
    []
  );
  const backAudios = useMemo(() => [back1, back2, back3], []);
  const { interviewState, messages, forceFinished } = useSelector(
    (state) => state.meeting
  );
  const { interviewStatus: status, interviewId } = useInterview();
  const numberOfAnswer = (messages.length - 1) / 2;
  const [backAudio, setBackAudio] = useState({});
  const [jessicaAudio, setJessicaAudio] = useState(null);
  const backAudioRef = useRef(null);
  useEffect(() => {
    if (interviewState !== INTERVIEW_STATE.SENDING) {
      setJessicaAudio(null);
      setBackAudio({
        audio: status === INTERVIEW_STATUS.DONE ? backClap : null,
        volume: 0.6,
      });
      return;
    }
    const selectedJessicaSoundIndex = Math.floor(Math.random() * 3) % 3;
    setJessicaAudio(
      numberOfAnswer < 6
        ? jessicaAudios[numberOfAnswer][selectedJessicaSoundIndex]
        : jessicaAudios[(numberOfAnswer % 3) + 3][selectedJessicaSoundIndex]
    );
    if (numberOfAnswer === 0) {
      setBackAudio({ audio: backStart, volume: 0.6 });
    } else {
      setBackAudio({
        audio: backAudios[Math.floor(Math.random() * 3) % 3],
        volume: 0.4,
      });
    }
    // const saveLoadingSound = async () => {
    //   const jessicaSoundContents = [
    //     [
    //       "That's a solid start! Let me quickly note that down.",
    //       "Interesting! I'm capturing that first insight now.",
    //       "Great kickoff! Just documenting your answer.",
    //     ],
    //     [
    //       "Oh, that builds nicely on your first point! Adding that detail..",
    //       "Perfectly connected! Let's pin that down.",
    //       "Building seamlessly! I'm logging that extra detail now.",
    //     ],
    //     [
    //       "Such valuable depth! Ensuring it’s captured. Oh, by the way, if you ever feel the session is running too long, just mention at the end of your next answer that you’d like to finish, and we will wrap it up. I don’t take it personally! Remember, the longer the interview, the richer the content we can create.",
    //       "Incredibly insightful! I'm making sure to jot that down.Oh, by the way, if you ever feel the session is running too long, just mention at the end of your next answer that you’d like to finish, and we will wrap it up. I don’t take it personally! Remember, the longer the interview, the richer the content we can create.",
    //       "Deepening our understanding! Ensuring your insights are recorded. Oh, by the way, if you ever feel the session is running too long, just mention at the end of your next answer that you’d like to finish, and we will wrap it up. I don’t take it personally! Remember, the longer the interview, the richer the content we can create.",
    //     ],
    //     [
    //       "This is getting really interesting! Let me type this up.",
    //       "Your answers are fascinating! Quickly documenting this.",
    //       "Each response is more intriguing! Capturing every word.",
    //     ],
    //     [
    //       "Excellent points! These details are key, noting them down.",
    //       "Spot-on insights! Carefully recording these points.",
    //       "Every detail matters! Ensuring all is noted.",
    //     ],
    //     [
    //       "We’re building a great narrative together! Securing this part in our session.",
    //       "Concluding superbly! I'm finalizing the details now",
    //       "Fantastic wrap-up! Making sure all is set in our notes.",
    //     ],
    //   ];
    //   try {
    //     await axios.post(`${SERVER_URL}/messages/sound`, {
    //       interviewId,
    //       content:
    //         jessicaSoundContents[
    //           numberOfAnswer < 6 ? numberOfAnswer : (numberOfAnswer % 3) + 3
    //         ][selectedJessicaSoundIndex],
    //       index: numberOfAnswer,
    //     });
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };
    // saveLoadingSound();
  }, [
    backAudios,
    interviewId,
    interviewState,
    jessicaAudios,
    numberOfAnswer,
    status,
  ]);
  const dispatch = useDispatch();

  useEffect(() => {
    const endedClapping = () => {
      if (status === INTERVIEW_STATUS.DONE) dispatch(setPlayingAudio(true));
    };
    const backAudioElement = backAudioRef.current;
    if (backAudioElement && !forceFinished) {
      backAudioElement.addEventListener("ended", endedClapping);
    }
    return () => {
      if (backAudioElement && !forceFinished) {
        backAudioElement.removeEventListener("ended", endedClapping);
      }
    };
  }, [dispatch, status, backAudio.audio, forceFinished]);

  return (
    <>
      {backAudio?.audio && (
        <audio
          src={backAudio.audio}
          autoPlay
          loop={status !== INTERVIEW_STATUS.DONE}
          volume={backAudio.volume}
          ref={backAudioRef}
        />
      )}
      {jessicaAudio && <audio src={jessicaAudio} autoPlay volume={0.5} />}
    </>
  );
};

export default WaitingPlayer;

import React, { useRef } from "react";
// import HelpCircle from "assets/images/help-circle.svg";
import { useDispatch } from "react-redux";
import warning from "assets/images/schedule/warning.svg";
import { setOpenPopup } from "store/meetingSlice";

const AllowMicroPhone = () => {
  const dispatch = useDispatch();
  const popupRef = useRef(null);

  const closePopup = (e, force = false) => {
    if (force || (popupRef.current && !popupRef.current.contains(e.target))) {
      dispatch(setOpenPopup(false));
    }
  };

  return (
    <div
      className="fixed w-full h-full bg-[#131313aa] flex items-center justify-center z-[100] min-h-max p-2.5 top-0"
      onClick={closePopup}
    >
      <div
        className="relative rounded-[20px] sm:rounded-[30px] border-[0.59px] border-solid bg-[#f9f1ed] w-[519px] max-w-100%"
        style={{
          boxShadow:
            "0px 6.157434463500977px 88.47000122070312px 0px #959da524",
        }}
        ref={popupRef}
      >
        <div className="flex flex-col gap-6 px-6 py-16">
          <div className="flex flex-col gap-3 items-center">
            <div className="p-4 rounded-[12px] bg-orange-mishmish">
              <img src={warning} alt="error" className="w-6 h-6" />
            </div>
            <div className="text-gray-900 font-figtree text-[16px] leading-[125%] tracking-[-0.16px] flex flex-col gap-3 items-center">
              <span className="font-[700]" style={{ color: "#101828" }}>
                Attention
              </span>
              <span style={{ color: "#101828" }} className="text-center">
                We just requested permission to the record the call. Accept the
                request to continue.
              </span>
            </div>
          </div>
          <div className="flex justify-center w-full">
            <button
              className="link interact py-2 px-12 sm:py-3 bg-primary-main text-white font-inter text-[16px] font-[600] leading-[150%] rounded-[40px] max-w-full w-[235px]"
              onClick={closePopup}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllowMicroPhone;

import React from "react";
import TooltipIcon from "assets/images/Tooltip.svg";
import "./Tooltip.css"

const Tooltip = ({ text, children, className }) => {
  return (
    <div className={`tooltip-container ${className}`}>
      {children}
      <div className="tooltip-body">
        <span >{text}</span>
        <img src={TooltipIcon} alt="tooltip-icon" />
      </div>
    </div>
  );
};

export default Tooltip;

import React from "react";
import Room from "./Room";
import { INTERVIEW_STATUS } from "Utils";
import Transcription from "./Transcription";
import InterviewTips from "./InterviewTips";
import useInterview from "hooks/useInterview";

const MainBox = () => {
  const { interviewStatus, assistantName, name } = useInterview();
  return (
    <div
      className={`main${interviewStatus === INTERVIEW_STATUS.DONE ? " finished" : ""}`}
    >
      <div className="names capitalize">
       {assistantName} &lt;&gt; {name}
      </div>
      {/* <div className="topic">
        Topic
      </div> */}
      <div className="conversation">
        <InterviewTips />
        <Room />
        <Transcription />
      </div>
    </div>
  );
};

export default MainBox;

import { createSlice } from "@reduxjs/toolkit";
import { INTERVIEW_STATUS } from "Utils";

const initialState = {
  interviewId: null,
  status: INTERVIEW_STATUS.CREATED,
  assistantName: "",
  name: "Netanel Baruch",
  image: null,
  assistantImage: null
};

const interviewSlice = createSlice({
  name: "interview",
  initialState,
  reducers: {
    setInterviewId: (state, action) => {
      state.interviewId = action.payload;
    },
    setInterviewStatus: (state, action) => {
      state.status = action.payload;
    },
    setAssistantName: (state, action) => {
      state.assistantName = action.payload;
    },
    setAssistantImage: (state, action) => {
      state.assistantImage = action.payload;
    },
    initInterviewData: () => {
      return { ...initialState };
    },
    setImage: (state, action) => {
      state.image = action.payload
    },
    setName: (state, action) => {
      state.name = action.payload
    }
  },
});

export const {
  setInterviewId,
  setInterviewStatus,
  setImage,
  initInterviewData,
  setAssistantName,
  setAssistantImage,
  setName
} = interviewSlice.actions;

export default interviewSlice.reducer;
